import { lazy, Route } from '@tanstack/router'

import type { CasinoGameSearch } from '../../types/route-search'
import { casinoRoute } from '.'

const Game = lazy(() => import('../../pages/game'))

export const gameRoute = new Route({
  getParentRoute: () => casinoRoute,
  path: '$category/$game',
  component: Game,
  validateSearch: (search: Record<string, unknown>): CasinoGameSearch => {
    return {
      gameUrl: typeof search.gameUrl === 'string' ? search.gameUrl : '',
      gameCode: typeof search.gameCode === 'string' ? search.gameCode : '',
      gameId: typeof search.gameId === 'string' ? search.gameId : '',
      isFavorite:
        typeof search.isFavorite === 'string'
          ? (search.isFavorite as '1' | '0')
          : '0',
    }
  },
})
