import { Router } from '@tanstack/router'
import qs from 'qs'

import { ErrorPage } from './components/common'
import { QS_CONFIG } from './constants/api'
import { indexRoute } from './routes'
import { affiliate1076Route } from './routes/affiliate-1076'
import { affiliateHomeRoute } from './routes/affiliate-home'
import { affiliateSmsRoute } from './routes/affiliate-sms'
import { announcementsRoute } from './routes/announcements'
import { betbySportsRoute } from './routes/betby-sports'
import { bingoRegisterRoute } from './routes/bingo-register'
import { blogRoute } from './routes/blogs'
import { blogDetailsRoute } from './routes/blogs/blog-details'
import { blogsListRoute } from './routes/blogs/blog-list'
import { btiSportsRoute } from './routes/bti-sports'
import { casinoRoute } from './routes/casino'
import { casinoCategoryRoute } from './routes/casino/category'
import { gameRoute } from './routes/casino/game'
import { casinoAdsRoute } from './routes/casino-ads'
import { casinoRegisterRoute } from './routes/casino-register'
import { competitionsRoute } from './routes/competitions'
import { contactUsRoute } from './routes/contact-us'
import { fallbackMxRoute } from './routes/fallback-mx'
import { footerPageRoute } from './routes/footer-page'
import { gameDepositRoute } from './routes/game-deposit'
import { helpPageRoute } from './routes/help-page'
import { imSportsRoute } from './routes/im-sports'
import { loginRoute } from './routes/login'
import { notFoundRoute } from './routes/not-found'
import { pokerRegisterRoute } from './routes/poker-register'
import { profileRoute } from './routes/profile'
import { affiliateRoute } from './routes/profile/affiliate'
import { affiliateIndexRoute } from './routes/profile/affiliate/affiliate'
import { affiliatePolicyRoute } from './routes/profile/affiliate/affiliate-policy'
import { memberListRoute } from './routes/profile/affiliate/member-list'
import { appFeedbackRoute } from './routes/profile/app-feedback'
import { commissionRoute } from './routes/profile/commission'
import { financialTransactionRoute } from './routes/profile/financial-transaction'
import { freeBonusRoute } from './routes/profile/free-bonus'
import { freeSpinsRoute } from './routes/profile/free-spins'
import { inboxRoute } from './routes/profile/inbox'
import { kycRoute } from './routes/profile/kyc'
import { profileIndexRoute } from './routes/profile/profile'
import { referralRoute } from './routes/profile/referral'
import { invitationCodesRoute } from './routes/profile/referral/invitation-codes'
import { myInvitationRoute } from './routes/profile/referral/my-invitation'
import { referralIndexRoute } from './routes/profile/referral/referral'
import { referralPolicyRoute } from './routes/profile/referral/referral-policy'
import { transactionHistoryRoute } from './routes/profile/transaction-history'
import { transactionLimitRoute } from './routes/profile/transaction-limit'
import { wageringTransactionRoute } from './routes/profile/wagering-transaction'
import { promotionsRoute } from './routes/promotions'
import { promoTermsRoute } from './routes/promotions/promo-terms'
import { promotionsIndexRoute } from './routes/promotions/promotions'
import { registerRoute } from './routes/register'
import { rootRoute } from './routes/root'
import { ruletaRegisterRoute } from './routes/ruleta-register'
import { slotRegisterRoute } from './routes/slot-register'
import { sponsorsRoute } from './routes/sponsors'
import { sportAdsRoute } from './routes/sport-landing-page'
import { sportsRegister } from './routes/sports-register'

const routeTree = rootRoute.addChildren([
  indexRoute,
  casinoRoute.addChildren([casinoCategoryRoute, gameRoute]),
  affiliateHomeRoute,
  announcementsRoute,
  blogRoute.addChildren([blogsListRoute, blogDetailsRoute]),
  competitionsRoute,
  contactUsRoute,
  helpPageRoute,
  footerPageRoute,
  profileRoute.addChildren([
    profileIndexRoute,
    affiliateRoute.addChildren([
      affiliateIndexRoute,
      affiliatePolicyRoute,
      memberListRoute,
    ]),
    commissionRoute,
    financialTransactionRoute,
    freeBonusRoute,
    freeSpinsRoute,
    inboxRoute,
    kycRoute,
    referralRoute.addChildren([
      referralIndexRoute,
      invitationCodesRoute,
      myInvitationRoute,
      referralPolicyRoute,
    ]),
    transactionHistoryRoute,
    appFeedbackRoute,
    wageringTransactionRoute,
    transactionLimitRoute,
  ]),
  promotionsRoute.addChildren([promotionsIndexRoute, promoTermsRoute]),
  registerRoute,
  betbySportsRoute,
  imSportsRoute,
  btiSportsRoute,
  sportsRegister,
  casinoAdsRoute,
  bingoRegisterRoute,
  casinoRegisterRoute,
  pokerRegisterRoute,
  ruletaRegisterRoute,
  slotRegisterRoute,
  sportAdsRoute,
  loginRoute,
  affiliateSmsRoute,
  affiliate1076Route,
  sponsorsRoute,
  gameDepositRoute,
  fallbackMxRoute,
  notFoundRoute, // Should be always last
])

export function customStringifier(searchObj: unknown) {
  return encodeURI(
    qs.stringify(searchObj, { ...QS_CONFIG, addQueryPrefix: true }),
  )
}

function customParser(searchString: string) {
  return qs.parse(searchString, { ...QS_CONFIG, ignoreQueryPrefix: true })
}

export const router = new Router({
  routeTree,
  defaultPreload: 'intent',
  defaultErrorComponent: ({ error }) => (
    <ErrorPage errorMessage={error.message} />
  ),
  stringifySearch: customStringifier,
  parseSearch: customParser,
})

declare module '@tanstack/router' {
  interface Register {
    router: typeof router
  }
}
