import { lazy,Route } from '@tanstack/router'

import { referralRoute } from '.'

const InvitationCodes = lazy(
  () => import('../../../pages/profile/referral/invitation-codes'),
)

export const invitationCodesRoute = new Route({
  getParentRoute: () => referralRoute,
  path: 'codes',
  component: InvitationCodes,
})
