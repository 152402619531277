import { useModal } from '@ebay/nice-modal-react'
import { type PropsWithChildren, useEffect } from 'react'
import { Portal } from 'react-portal'

import classNames from '../../utils/classNames'
import closeModal from '../../utils/closeModal'
import Backdrop from './backdrop'

type ModalProps = PropsWithChildren<{
  id: string
  containerStyle?: string
  zIndex?: number
  onModalClose?: () => void
  disableOverlayClose?: boolean
  hideOverlay?: boolean
}>

const Modal = ({
  children,
  containerStyle = '',
  id,
  zIndex,
  onModalClose,
  disableOverlayClose,
  hideOverlay,
}: ModalProps) => {
  const modal = useModal()

  const isVisible = modal.visible

  useEffect(() => {
    isVisible
      ? document.body.classList.add('noscroll')
      : document.body.classList.remove('noscroll')
  }, [isVisible])

  return (
    <Portal>
      {!hideOverlay && (
        <Backdrop
          id={id}
          isVisible={isVisible}
          onClick={() => {
            onModalClose?.()
            !disableOverlayClose && closeModal(modal)
          }}
          zIndex={zIndex ?? 203}
        />
      )}
      <div
        className={classNames(
          'fixed w-[calc(100%_-_60px)] max-w-[400px] bg-primary pt-[35px] pb-[30px] px-5 rounded-[20px] border-2 border-solid border-tertiary left-2/4 top-2/4',
          isVisible
            ? 'opacity-100 pointer-events-auto -translate-x-2/4 -translate-y-2/4'
            : 'opacity-0 pointer-events-none -translate-x-2/4 translate-y-[calc(-50%_-_50px]',
          containerStyle,
        )}
        style={{
          zIndex: zIndex ? zIndex + 1 : 204,
        }}>
        {children}
      </div>
    </Portal>
  )
}

export default Modal
