import { useRouter } from '@tanstack/router'
import { useTranslation } from 'react-i18next'

import useDimensions from '../../hooks/useDimensions'
import Icon from '../icon'

const Header = () => {
  const { isDesktop } = useDimensions()
  const { t } = useTranslation('announcementModal')
  const { history } = useRouter()

  return (
    <div className="relative mb-[30px] pl-[50px] xl:mb-6 xl:pl-6">
      {!isDesktop && (
        <div
          className="absolute flex left-3 top-[15px]"
          onClick={() => history.back()}>
          <Icon id="back" width="35" height="35" />
        </div>
      )}
      <div className="flex absolute pointer-events-none right-[15px] top-2.5 xl:right-6 xl:top-2.5">
        <Icon
          className="xl:w-[45px]"
          id="announcement"
          width="40"
          height="43"
        />
      </div>
      <div className="text-sm text-txt w-60 pt-[27px] pb-0 px-5 font-Syncopate-Bold xl:text-lg xl:w-[270px] xl:pt-[27px] xl:pb-0 xl:px-0">
        {t('latest-announcements')}
      </div>
    </div>
  )
}

export default Header
