import './all-announcements-modal.css'

import NiceModal from '@ebay/nice-modal-react'
import { useEffect, useState } from 'react'

import useDimensions from '../../../hooks/useDimensions'
import type { AnnouncementObj } from '../../../types/announcement'
import type { ApiResponse } from '../../../types/api'
import { api } from '../../../utils/api-client'
import { AnnouncementItem, Header } from '../../announcements'
import Modal from '../modal'
import Spinner from '../spinner'

const AllAnnouncementsModal = () => {
  const { isDesktop } = useDimensions()
  const [isLoading, setIsLoading] = useState(false)
  const [announcements, setAnnouncements] = useState<AnnouncementObj[]>([])

  useEffect(() => {
    setIsLoading(true)
    api
      .post<ApiResponse<AnnouncementObj[]>>('/ajax_check.php', {
        type: 'get_notice',
        num: 1,
      })
      .then(res => {
        const { status, info } = res.data
        if (status === 1) {
          setAnnouncements(info)
        }
        setIsLoading(false)
      })
      .catch(err => {
        console.error(err)
        setIsLoading(false)
      })
  }, [])

  if (!isDesktop) return null

  const renderList = () => {
    if (isLoading) return <Spinner containerStyle={{ height: '200px' }} />

    return announcements.map((item, index) => (
      <AnnouncementItem key={index} {...item} />
    ))
  }

  return (
    <Modal
      id="all-announcements-modal"
      containerStyle="max-w-[800px] rounded-lg border-0">
      <Header />
      <div className="px-6 pb-3 h-[500px] overflow-y-auto all-announcements-modal">
        {renderList()}
      </div>
    </Modal>
  )
}

export default NiceModal.create(AllAnnouncementsModal)
