import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'
const Login = lazy(() => import('../pages/login'))

export const loginRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/login',
  component: Login,
})
