import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'

const HelpPage = lazy(() => import('../pages/help-page'))

export const helpPageRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'help/$page',
  component: HelpPage,
})
