import { lazy, Route } from '@tanstack/router'

import type { RegisterSearch } from './register'
import { rootRoute } from './root'

const SlotRegister = lazy(() => import('../pages/slot-register'))

export const slotRegisterRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/slot-register',
  component: SlotRegister,
  validateSearch: (search: Record<string, unknown>): RegisterSearch => {
    return search
  },
})
