import { lazy,Route } from '@tanstack/router'

import protectRoute from '../../hoc/protectRoute'
import { rootRoute } from '../root'

const Profile = lazy(() => import('../../pages/profile/profile'))

export const profileRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'profile',
  component: protectRoute(Profile, true),
})
