import md5 from 'crypto-js/md5'

import { api } from './api-client'

export const getPublicAuth = async () => {
  const key = 'fghrtrvdfger'
  const serverTime = await api.get<number>('/increase.php')

  return md5(`${serverTime.data.toString().substring(0, 7)}${key}`).toString()
}
