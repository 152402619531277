import { lazy,Route } from '@tanstack/router'

import { referralRoute } from '.'

const ReferralPolicy = lazy(
  () => import('../../../pages/profile/referral/referral-policy'),
)

export const referralPolicyRoute = new Route({
  getParentRoute: () => referralRoute,
  path: 'referral-policy',
  component: ReferralPolicy,
})
