import type { AnnouncementObj } from '../../types/announcement'

const AnnouncementItem = ({ edit_time, content }: AnnouncementObj) => {
  return (
    <div className="bg-tertiary justify-between transition-all duration-[0.2s] ease-[ease-in-out] gap-2.5 overflow-hidden mb-3 p-4 rounded-[10px]">
      <p className="text-base text-txt mb-3 xl:text-lg">{edit_time}</p>
      <p className="text-base text-txt xl:text-lg">{content}</p>
    </div>
  )
}

export default AnnouncementItem
