import type { BlazeConfig } from 'blaze-slider'
import BlazeSlider from 'blaze-slider'
import { useEffect, useRef,useState } from 'react'

function useBlazeSlider(config: BlazeConfig) {
  const sliderRef = useRef<BlazeSlider | null>(null)
  const elRef = useRef<HTMLDivElement | null>(null)
  const [activeKey, setActiveKey] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      if (!sliderRef.current && elRef.current) {
        sliderRef.current = new BlazeSlider(elRef.current, config)
      }
    }, 2000)
  }, [])

  useEffect(() => {
    let unsubscribe: undefined | (() => boolean)
    setTimeout(() => {
      unsubscribe = sliderRef.current?.onSlide(pageIndex => {
        setActiveKey(pageIndex)
      })
    }, 2000)

    return () => {
      unsubscribe?.()
    }
  }, [])

  const slideTo = (index: number) => {
    const diff = index - activeKey
    if (diff > 0) {
      sliderRef.current?.next(diff)
    } else {
      sliderRef.current?.prev(-diff)
    }
  }

  return { elRef, sliderRef, activeKey, slideTo }
}

export default useBlazeSlider
