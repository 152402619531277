import type { ButtonHTMLAttributes, CSSProperties, ReactNode } from 'react'

import classNames from '../../utils/classNames'

interface DefaultButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'blue' | 'green' | 'gray' | 'default'
  size?: 'large' | 'small' | 'default'
  children: ReactNode | string
  type?: 'submit' | 'button'
  onClick?: () => unknown
  className?: string
  width?: CSSProperties['width']
  disabled?: boolean
}

const DefaultButton = ({
  variant = 'default',
  children,
  className = '',
  width,
  size = 'default',
  style = {},
  ...rest
}: DefaultButtonProps) => {
  const COLORS = {
    blue: 'bg-highlight text-primary-btn-txt',
    green: 'bg-primary-btn-bg text-primary-btn-txt',
    gray: 'bg-[#65676a] text-primary-btn-txt',
    default: 'bg-secondary-btn-bg text-secondary-btn-txt',
  }

  const SIZES = {
    small: 'leading-[35px] xl:text-base xl:leading-10',
    large: 'text-base leading-[46px] xl:text-lg xl:leading-[50px]',
    default: 'text-sm leading-10 xl:text-base xl:leading-[46px]',
  }

  return (
    <button
      className={classNames(
        'text-center block px-3 py-0 rounded-md disabled:opacity-50',
        COLORS[variant],
        SIZES[size],
        className,
      )}
      style={{ width, ...style }}
      {...rest}>
      {children}
    </button>
  )
}

export default DefaultButton
