import { lazy,Route } from '@tanstack/router'

import { blogRoute } from '.'

const BlogDetails = lazy(() => import('../../pages/blogs/blog-details'))

export const blogDetailsRoute = new Route({
  getParentRoute: () => blogRoute,
  path: '$category/$slug',
  component: BlogDetails,
})
