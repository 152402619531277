import { Route, useNavigate } from '@tanstack/router'
import { useEffect } from 'react'

import { rootRoute } from './root'

export const fallbackMxRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'mx',
  component: () => {
    const navigate = useNavigate()

    useEffect(() => {
      navigate({ to: '/' })
    }, [])

    return null
  },
})
