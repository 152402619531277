import { lazy, Route } from '@tanstack/router'

import { profileRoute } from '.'

const WageringTransaction = lazy(
  () => import('../../pages/profile/wagering-transaction'),
)

export const wageringTransactionRoute = new Route({
  getParentRoute: () => profileRoute,
  path: 'wagering-transaction',
  component: WageringTransaction,
})
