import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { Outlet, RootRoute } from '@tanstack/router'
import { Provider as BusProvider } from 'react-bus'
import { Helmet } from 'react-helmet'

import { ErrorPage } from '../components/common'
import BottomNav from '../components/layouts/bottom-nav'
import useAdsInfo from '../hooks/useAdsInfo'
import useAnnouncements from '../hooks/useAnnouncements'
import useContinue from '../hooks/useContinue'
import useDepositEvents from '../hooks/useDepositEvents'
import useDimensions from '../hooks/useDimensions'
import useFullStory from '../hooks/useFullStory'
import usePaymentChannels from '../hooks/usePaymentChannels'
import useScrollToTop from '../hooks/useScrollToTop'
import useSettings from '../hooks/useSettings'
import useTheme from '../hooks/useTheme'

export const rootRoute = new RootRoute({
  component: () => {
    const { isDesktop } = useDimensions()
    useTheme()
    useContinue()
    useAnnouncements()
    useScrollToTop()
    useSettings()
    useDepositEvents()
    usePaymentChannels()
    useAdsInfo()
    useFullStory()

    return (
      <BusProvider>
        <NiceModalProvider>
          <Helmet>
            <link
              rel="canonical"
              href={window.location.href.split('?')[0].replace(/\/+$/, '')}
            />
          </Helmet>
          <Outlet />
          {!window.ReactNativeWebView &&
            !isDesktop &&
            !/\/casino\/.+\/.+/.test(location.pathname) && <BottomNav />}
        </NiceModalProvider>
      </BusProvider>
    )
  },
  errorComponent: ErrorPage,
})
