import { lazy, Route } from '@tanstack/router'

import type { RegisterSearch } from './register'
import { rootRoute } from './root'

const RuletaRegister = lazy(() => import('../pages/ruleta-register'))

export const ruletaRegisterRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/ruleta-register',
  component: RuletaRegister,
  validateSearch: (search: Record<string, unknown>): RegisterSearch => {
    return search
  },
})
