import { lazy,Route } from '@tanstack/router'

import { profileRoute } from '.'

const TransactionLimit = lazy(
  () => import('../../pages/profile/transaction-limit'),
)

export const transactionLimitRoute = new Route({
  getParentRoute: () => profileRoute,
  path: 'transaction-limit',
  component: TransactionLimit,
})
