import { lazy,Route } from '@tanstack/router'

import { profileRoute } from '.'

const PersonalData = lazy(() => import('../../pages/profile/personal-data'))

export const profileIndexRoute = new Route({
  getParentRoute: () => profileRoute,
  path: '/',
  component: PersonalData,
})
