import { lazy,Route } from '@tanstack/router'

import { useBoundStore } from '../../store/store'
import type { GamesSummary } from '../../types/game'
import type { CasinoSearch } from '../../types/route-search'
import { api } from '../../utils/api-client'
import { decryptUser } from '../../utils/user'
import { casinoRoute } from '.'

const Casino = lazy(() => import('../../pages/casino'))

export const casinoCategoryRoute = new Route({
  getParentRoute: () => casinoRoute,
  path: '$category',
  component: Casino,
  validateSearch: (search: Record<string, unknown>): CasinoSearch => {
    return {
      platform: typeof search.platform === 'string' ? search.platform : '',
      error: typeof search.error === 'string' ? search.error : '',
      message: typeof search.message === 'string' ? search.message : '',
    }
  },
  loader: () => {
    const user = decryptUser()
    
    const preLoadCategories = async () => {
      const summmary = useBoundStore.getState().casino.summary
      const setSummmary = useBoundStore.getState().casino.setSummary
      if (summmary) return
      try {
        const res = await api.get<GamesSummary>(
          `/games/lists.php?account=${user?.username ?? ''}`,
        )
        setSummmary(res.data)
      } catch (error) {
        console.error(error)
      }
    }

    preLoadCategories()
  },
})
