import { lazy, Route } from '@tanstack/router'

import type { RegisterSearch } from './register'
import { rootRoute } from './root'

const PokerRegister = lazy(() => import('../pages/poker-register'))

export const pokerRegisterRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/poker-register',
  component: PokerRegister,
  validateSearch: (search: Record<string, unknown>): RegisterSearch => {
    return search
  },
})
