import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'

const ImSports = lazy(() => import('../pages/sports/im-sports'))

export const imSportsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/sports/im',
  component: ImSports,
})
