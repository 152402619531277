import type { CSSProperties } from 'react'

import { TailSpin } from '../loaders'

interface SpinnerProps {
  containerStyle?: CSSProperties
  spinnerSize?: number
}

const Spinner = ({ containerStyle, spinnerSize }: SpinnerProps) => {
  return (
    <div className="flx-ctr w-full" style={containerStyle}>
      <TailSpin
        color={'#00A6FF'}
        width={spinnerSize ?? 40}
        height={spinnerSize ?? 40}
      />
    </div>
  )
}

export default Spinner
