import { lazy,Route } from '@tanstack/router'

import { affiliateRoute } from '.'

const AffiliatePolicy = lazy(
  () => import('../../../pages/profile/affiliate/affiliate-policy'),
)

export const affiliatePolicyRoute = new Route({
  getParentRoute: () => affiliateRoute,
  path: 'affiliate-policy',
  component: AffiliatePolicy,
})
