import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useNavigate } from '@tanstack/router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useGeoCheck from '../../hooks/useGeoCheck'
import type { ApiResponse } from '../../types/api'
import { api } from '../../utils/api-client'
import classNames from '../../utils/classNames'
import closeModal from '../../utils/closeModal'
import Modal from './modal'
import Spinner from './spinner'

const RequestPasswordResetModal = () => {
  const { t } = useTranslation(['login', 'recoverPassword', 'register'])
  const { geoCheck } = useGeoCheck()
  const navigate = useNavigate()
  const modal = useModal()
  const [account, setAccount] = useState('')
  const [message, setMessage] = useState<{
    message: string
    isValid: boolean
  } | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const onSend = async () => {
    if (!account)
      return setMessage({
        message: t('username-required', { ns: 'register' }),
        isValid: false,
      })

    setIsLoading(true)
    const reqBody = {
      type: 'send_reset_password',
      account,
    }
    const response = await api.post<ApiResponse<string>>(
      '/request.php',
      reqBody,
    )
    const { info, status } = response.data
    setMessage({ message: info, isValid: status === 1 ? true : false })

    setIsLoading(false)
  }

  return (
    <Modal id="request-password-reset-modal">
      <p className="text-[15px] uppercase text-txt text-center mb-[25px]">
        {t('title', { ns: 'recoverPassword' })}
      </p>
      {message && (
        <p
          className={classNames(
            'text-[15px] mb-2.5',
            message.isValid ? 'text-[#4bca81]' : 'text-[rgb(255,64,88)]',
          )}>
          {message.message}
        </p>
      )}
      <p className="text-[15px] text-txt mb-[5px]">
        {t('enter-email', { ns: 'recoverPassword' })}
      </p>
      <input
        className="text-txt text-base w-full transition-all duration-[0.2s] ease-[ease-in-out] mb-2.5 px-0 py-[7px] rounded-none border-b-txt border-b border-solid placeholder:text-[#ccc] placeholder:dark:text-[rgba(255,255,255,0.6)]"
        name="email"
        placeholder={t('username-or-email', { ns: 'login' })}
        value={account}
        onChange={e => setAccount(e.target.value)}
      />
      <div className="flx-ctr" style={{ margin: '20px 0' }}>
        {isLoading ? (
          <Spinner />
        ) : (
          <button
            className="text-center text-[15px] bg-primary-btn-bg border-primary-btn-bg text-primary-btn-txt transition-all duration-[0.2s] ease-[ease-in-out] cursor-pointer font-normal block mx-auto my-0 px-6 py-[7px] rounded-[22px] border-[1.5px] border-solid"
            onClick={onSend}>
            {t('send-request', { ns: 'recoverPassword' })}
          </button>
        )}
      </div>
      <p className="text-[15px] text-center text-txt mb-[5px]">
        {t('opened', { ns: 'recoverPassword' })}{' '}
        <button
          onClick={() => {
            geoCheck(() => navigate({ to: '/register' }))
            closeModal(modal)
          }}
          className="text-txt underline">
          {t('join-now', { ns: 'recoverPassword' })}
        </button>
      </p>
    </Modal>
  )
}

export default NiceModal.create(RequestPasswordResetModal)
