import { lazy,Route } from '@tanstack/router'

import type { RegisterSearch } from './register'
import { rootRoute } from './root'

const SportLandingPage = lazy(() => import('../pages/sport-landing-page'))

export const sportAdsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/sport-lp',
  component: SportLandingPage,
  validateSearch: (search: Record<string, unknown>): RegisterSearch => {
    return search
  },
})
