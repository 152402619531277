import { useModal } from '@ebay/nice-modal-react'
import { useEffect } from 'react'

import LoginModal from '../components/common/log-in-modal'
import WalletManagementModal from '../components/wallet-management/wallet-management-modal'
import { decryptUser } from '../utils/user'
import useGeoCheck from './useGeoCheck'

function useContinue() {
  const logInModal = useModal(LoginModal)
  const walletManagementModal = useModal(WalletManagementModal)
  const { geoCheck } = useGeoCheck()
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    const continueTo = params.get('continue')
    const user = decryptUser()

    if (!continueTo) return
    if (!user) {
      geoCheck(logInModal.show)
      return
    }

    if (continueTo === 'deposit') {
      walletManagementModal.show({})
    }
  }, [params])
}

export default useContinue
