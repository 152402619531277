import { FullStory } from '@fullstory/browser'
import { useEffect } from 'react'

import { useBoundStore } from '../store/store'

const useFullStory = () => {
  const username = useBoundStore((state) => state.user.username)
  const email = useBoundStore((state) => state.user.email)

  useEffect(() => {
    if (!username) return

    FullStory('setIdentity', {
      uid: username,
      properties: {
        displayName: username,
        email,
      },
    })
  }, [username])
}

export default useFullStory