import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from '../../utils/classNames'
import Icon from '../icon'

interface TabsProps {
  activeTab: number
  setActiveTab: Dispatch<SetStateAction<number>>
  hide: () => void
}

const Tabs = ({ activeTab, setActiveTab, hide }: TabsProps) => {
  const { t } = useTranslation('walletManagement')

  return (
    <div className="flex mb-5">
      <button
        className={classNames(
          activeTab === 0 ? 'text-txt !bg-primary' : 'text-[#999]',
          'bg-[#e0e0ec] dark:bg-[#1a1a1c] flx-ctr left flex-1 h-10 font-medium uppercase text-base transition-all duration-[0.2s] ease-[ease-in-out] xl:text-lg xl:h-[50px] xl:rounded-tl-[10px]',
        )}
        onClick={() => setActiveTab(0)}>
        {t('deposit')}
      </button>
      <button
        className={classNames(
          activeTab === 1 ? 'text-txt !bg-primary' : 'text-[#999]',
          'bg-[#e0e0ec] dark:bg-[#1a1a1c] flx-ctr right flex-1 h-10  font-medium uppercase text-base transition-all duration-[0.2s] ease-[ease-in-out] xl:text-lg xl:h-[50px] xl:rounded-tr-[10px]',
        )}
        onClick={() => setActiveTab(1)}>
        {t('withdrawal')}
      </button>
      <button
        className="flx-ctr text-highlight underline bg-highlight px-4 py-2 xl:rounded-tr-[10px]"
        onClick={hide}>
        <Icon
          className="fill-white w-3.5 h-3.5"
          id="close"
          width={12}
          height={12}
        />
      </button>
    </div>
  )
}

export default Tabs
