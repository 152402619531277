import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'

const Announcement = lazy(() => import('../pages/announcements'))

export const announcementsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'announcements',
  component: Announcement,
})
