import { Portal } from 'react-portal'

interface LoadingProps {
  solidBg?: boolean
}

const Loading = ({ solidBg }: LoadingProps) => {
  return (
    <Portal>
      <div
        className={
          (solidBg ? 'bg-primary' : '') +
          'fixed w-screen h-screen z-[600] bg-secondary-txt-50 flex items-center justify-center left-0 top-0'
        }>
        <iframe
          className="w-[200px] h-[200px] border-[none] xl:w-[250px] xl:h-[250px]"
          src="https://fun88mx.mx/loading.html"
          style={{ outline: 'none' }}
        />
      </div>
    </Portal>
  )
}

export default Loading
